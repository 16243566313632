<template>
	<div v-if="canEmptyTrash">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn flat icon="delete_sweep" mini @click="doShowEmptyTrashDialog">{{ $t('actions.clean_trash') }}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click.stop="doShowEmptyTrashDialog">
			<v-list-tile-avatar>
				<v-icon color="primary">delete_sweep</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'actions.clean_trash'" />
			</v-list-tile-content>
		</v-list-tile>
		<EmptyTrashDialog
			v-if="showEmptyTrashDialog"
			:folder="currentEntry"
			@close="closeDialog"
		/>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

const name = 'EmptyTrashButton';

export default {
	name,
	components: {
		EmptyTrashDialog: () => ({
			component: import('@/components/Documents/Actions/Trash/EmptyTrashDialog')
		})
	},
	mixins: [DocumentActionModuleGuard],
	props: {
		value: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			showEmptyTrashDialog: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		currentEntry: function () {
			return this.value.length === 1 ? this.value[0] : null
		},
		canEmptyTrash: function () {
			return !this.isGuest && this.currentEntry?.is_trash
		}
	},
	methods: {
		...mapMutations({
			setCurrentDialog: 'dialogs/setCurrent'
		}),
		doShowEmptyTrashDialog: function () {
			if (this.canEmptyTrash) {
				this.setCurrentDialog(name)
				this.showEmptyTrashDialog = true
			}
		},
		closeDialog: function () {
			this.setCurrentDialog(null)
			this.showEmptyTrashDialog = false
		}
	}
}
</script>
